import { Box, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { toast } from "src/utils/toast";
import { qudraEmailService } from "../../../../../api/services/qudra-email";
import { IQudraEmail } from "../../../../../api/types/qudra-email";
import { Button } from "primereact/button";

type Props = {
  currentConfig: IQudraEmail;
};

const QudraEmailForm = (props: Props) => {
  const { currentConfig } = props;
  const [email, setEmail] = useState<string>(currentConfig.email);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setError(""); // Reset error when user starts typing
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // Basic email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError("Please enter a valid email address");
      return;
    }
    console.log("Submitted email:", email);
    setLoading(true);
    await qudraEmailService.update({ email });
    setLoading(false);
    toast.success("Config saved successfully!");
  };

  return (
    <>
      <Box
        sx={{
          maxWidth: 400,
          mx: "auto",
          p: 3,
          border: "1px solid #ddd",
          borderRadius: 2,
          boxShadow: 3,
        }}>
        <Typography variant="h5" align="center" gutterBottom>
          Enter Admin Email
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Email Address"
            variant="outlined"
            fullWidth
            value={email}
            onChange={handleChange}
            error={!!error}
            helperText={error || "Enter a valid email address"}
            margin="normal"
            type="text"
          />
          <Button
            label={"Save Email"}
            className="p-button-primary w-full"
            type="submit"
            loading={loading}
          />
        </form>
      </Box>
    </>
  );
};

export default QudraEmailForm;
