import { API_ROUTES } from "src/constants/api-routes";
import { AxiosInstance } from "../index";
import {
  IPaginatedRequestBody,
  PaginatedResponse,
  IAxiosResponse,
} from "../types/axios-response";
import {
  IContract,
  IContractListItem,
  IAvailableActionResponse,
  IChangeStateBody,
  IChannelInfo,
  IContractStatusHistory,
  IUploadReceiptImageBody,
} from "../types/contracts";

export const contractService = {
  getAll(data?: IPaginatedRequestBody) {
    return AxiosInstance.post<PaginatedResponse<IContractListItem>>(
      API_ROUTES.CONTRACTS.ROOT,
      data || {},
    );
  },
  export(data?: IPaginatedRequestBody) {
    return AxiosInstance.post<Blob>(API_ROUTES.CONTRACTS.EXPORT, data || {});
  },
  getDetails(id: string) {
    return AxiosInstance.get<IAxiosResponse<IContract>>(
      API_ROUTES.CONTRACTS.ROOT + "/" + id,
    );
  },
  getContractAvailableActions(id: number) {
    return AxiosInstance.get<IAxiosResponse<IAvailableActionResponse>>(
      API_ROUTES.CONTRACTS.GET_AVAILABLE_ACTIONS(id),
    );
  },
  changeState(contractId: number, data?: IChangeStateBody) {
    return AxiosInstance.put<IAxiosResponse<IContract>>(
      API_ROUTES.CONTRACTS.CHANGE_STATE(contractId),
      data || {},
    );
  },
  getChannelInfo(contractId: string) {
    return AxiosInstance.get<IAxiosResponse<IChannelInfo>>(
      `${API_ROUTES.CONTRACTS.ROOT}/${contractId}/channel-info`,
    );
  },
  getStatusHistory(contractId: string) {
    return AxiosInstance.post<PaginatedResponse<IContractStatusHistory>>(
      `${API_ROUTES.CONTRACTS.ROOT}/${contractId}/flows`,
      {
        skip: 0,
        take: 1000,
      },
    );
  },
  uploadReceiptImage(contractId: number, data?: IUploadReceiptImageBody) {
    return AxiosInstance.put<IAxiosResponse<IContract>>(
      API_ROUTES.CONTRACTS.UPLOAD_RECEIPT_IMAGE(contractId),
      data || {},
    );
  },
};
