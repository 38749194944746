import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { DataTable, DataTablePFSEvent } from "primereact/datatable";
import { classNames } from "primereact/utils";
import React, { useCallback, useEffect, useState } from "react";
import { partnersService } from "src/api/services/partners";
import { ICategory } from "src/api/types/category";
import { staticImages } from "src/assets";
import ListingPage from "src/components/App/ListingPage";
import CustomCard from "src/components/Kit/CustomCard";
import { DEFAULT_PAGE_SIZE } from "src/constants/pagination";
import { IPartner } from "../../../api/types/partner";
import NewPartner from "./components/NewPartner";
import "./styles.css";

interface IModalDataState {
  show: boolean;
  data?: ICategory;
}

const PartnerManagement: React.FC = () => {
  const [activationTargetLoading, setActivationTargetLoading] =
    useState<number>(-1);
  const [loading, setLoading] = useState<boolean>(false);
  const [modal, setModal] = useState<IModalDataState>({
    show: false,
    data: undefined,
  });
  const [categories, setCategories] = useState<IPartner[]>([]);

  const [totalRecords, setTotalRecords] = useState(0);
  const [lazyParams, setLazyParams] = useState<DataTablePFSEvent>({
    filters: {},
    first: 0,
    multiSortMeta: [],
    page: 0,
    pageCount: 2,
    rows: 5,
    sortField: "",
    sortOrder: null,
  });

  const onPageChange = (e: DataTablePFSEvent) => {
    setLazyParams(e);
  };

  const changeCategoryActivation = (rowData: IPartner) => {
    confirmDialog({
      message: `Are you sure you want to ${
        rowData.isActive ? "deactivate" : "activate"
      } ${rowData.title}`,
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: async () => {
        try {
          const tempCategories = [...categories];
          setActivationTargetLoading(rowData.id);
          await partnersService.changeActivation(rowData.id, !rowData.isActive);
          const targetItemIndex = tempCategories.indexOf(rowData);
          tempCategories[targetItemIndex].isActive = !rowData.isActive;
          setCategories(tempCategories);
        } catch (err) {
          console.log(err);
        } finally {
          setActivationTargetLoading(-1);
        }
      },
    });
  };

  const changeActivationTemplate = (rowData: IPartner) => {
    return (
      <>
        <Button
          label={rowData.isActive ? "Active" : "Inactive"}
          className={classNames("p-button-outlined p-button-sm", {
            "p-button-danger": !rowData.isActive,
            "p-button-secondary": rowData.isActive,
          })}
          type="button"
          style={{ minWidth: "100px" }}
          loading={activationTargetLoading === rowData.id}
          onClick={() => {
            changeCategoryActivation(rowData);
          }}
        />
      </>
    );
  };

  const editCategoryTemplate = (rowData: ICategory) => {
    return (
      <div
        className="p-1 cursor-pointer"
        onClick={() => setModal({ show: true, data: rowData })}>
        <i className={"pi pi-pencil"} style={{ fontSize: "18px" }} />
      </div>
    );
  };

  const imageBodyTemplate = (rowData: IPartner) => {
    return (
      <div className="category-list-image-wrapper">
        <img
          className="category-list-image"
          alt="partner"
          src={rowData.images ? rowData.images[0].url : staticImages.noImage}
        />
      </div>
    );
  };

  const fetchCategories = useCallback(async () => {
    try {
      setLoading(true);
      const result = await partnersService.getAll({
        skip: lazyParams.page! * DEFAULT_PAGE_SIZE,
        take: (lazyParams.page! + 1) * DEFAULT_PAGE_SIZE,
      });
      setTotalRecords(result.data.payload.pagination.totalItems);
      setCategories(result.data.payload.items);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [lazyParams]);

  const onNewCategoryAdded = (data: IPartner) => {
    if (lazyParams.page === 0) {
      setCategories([data, ...categories]);
    }
  };

  const onNewCategoryUpdate = (data: IPartner) => {
    let tempList = categories.map((obj) => ({ ...obj }));
    tempList.forEach((tempCat) => {
      if (tempCat.id === data.id) {
        tempCat.images = data.images;
        tempCat.title = data.title;
        tempCat.isActive = data.isActive;
        tempCat.description = data.description;
      }
      return tempCat;
    });
    setCategories(tempList);
  };

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  return (
    <>
      <ListingPage title={"Partners"}>
        <NewPartner onNewCategoryAdded={onNewCategoryAdded} />
        <CustomCard>
          <DataTable
            value={categories}
            emptyMessage="No Partner found."
            loading={loading}
            lazy
            first={lazyParams.first}
            paginator
            rows={DEFAULT_PAGE_SIZE}
            totalRecords={totalRecords}
            onPage={onPageChange}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            dataKey="id"
            className="qud-table">
            <Column field="id" header="ID" />
            <Column field="image" header="Image" body={imageBodyTemplate} />
            <Column field="title" header="Title" />
            <Column field="description" header="Description" />
            <Column field="website" header="Website" />

            <Column
              field="status"
              header="Status"
              body={changeActivationTemplate}
            />
            {/* <Column field="edit" header="Edit" body={editCategoryTemplate} /> */}
          </DataTable>
        </CustomCard>
      </ListingPage>
      <ConfirmDialog />
      {/* {modal.show && (
        <UpdateCategoryModal
          show={modal.show}
          modalData={modal.data}
          onHide={() => setModal({ show: false, data: undefined })}
          onNewCategoryUpdate={onNewCategoryUpdate}
        />
      )} */}
    </>
  );
};
export default PartnerManagement;
