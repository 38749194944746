import React, { useState } from "react";
import { modalStyle } from "src/constants/modal-styles";
import Modal from "react-modal";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object } from "yup";
import { Controller, useForm } from "react-hook-form";
import FormFooterActions from "src/components/Kit/FormFooterActions";
import FormFieldWrapper from "src/components/Kit/FormFieldWrapper";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import {
  IAvailableAction,
  IAvailableActionDropDown,
  IContract,
} from "src/api/types/contracts";
import { contractService } from "src/api/services/contract";
import { toast } from "src/utils/toast";
import { getAxiosError } from "src/utils/get-axios-error";
import GeneralFormErrorMessage from "src/components/App/GeneralFormErrorMessage";
import Avatar from "src/components/App/Avatar";
import { staticImages } from "src/assets";

interface IContactModalProps {
  show: boolean;
  data: IAvailableActionDropDown;
  onHide: () => void;
  contractId: number;
  updateStateActions: (actionsResponse: IAvailableAction[]) => void;
  updateContractDetail: (contractDetails: IContract) => void;
}
interface IContactComment {
  comment: string;
}
const UploadReciptImageModal: React.FC<IContactModalProps> = ({
  show,
  data,
  onHide,
  contractId,
  updateStateActions,
  updateContractDetail,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [apiError, setApiError] = useState<string>("");
  const [mediaId, setMediaId] = useState<number | undefined>();

  const onFormSubmit = async (e: any) => {
    e.preventDefault();
    if (loading) return;
    if (!mediaId) {
      toast.error("Image is required!");
      return;
    }

    const tempData = {
      mediaId: mediaId,
    };

    setLoading(true);
    try {
      let contractDetailResponse = await contractService.uploadReceiptImage(
        contractId,
        tempData,
      );
      let nextActions = await contractService.getContractAvailableActions(
        contractId,
      );

      updateContractDetail(contractDetailResponse.data.payload);
      updateStateActions(nextActions.data.payload.actions);
      toast.success("Contract status updated successfully!");

      onHide();
    } catch (err) {
      const error = getAxiosError(err);
      const message = error.meta?.message || "Server Error";

      setApiError(message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    onHide();
  };

  const onAvatarChanged = (id: number) => {
    setMediaId(id);
  };
  const setUploadingImage = (value: boolean) => {
    setLoading(value);
  };

  return (
    <Modal
      ariaHideApp={false}
      style={modalStyle}
      contentLabel={"Update linked account"}
      isOpen={show}
      onRequestClose={handleCloseModal}>
      <div className="flex flex-row justify-content-between">
        <span style={{ fontWeight: "600" }}>{data?.key}</span>
        <div className="p-1 cursor-pointer" onClick={handleCloseModal}>
          <i
            className={"pi pi-times"}
            style={{ fontSize: "1em", color: "var(--black)" }}
          />
        </div>
      </div>
      <form className="mt-5" noValidate onSubmit={onFormSubmit}>
        {apiError && <GeneralFormErrorMessage message={apiError} />}
        <div className="flex flex-column gap-2">
          <label className="font-semibold text-sm">Image</label>
          <Avatar
            src={staticImages.noImage}
            editable
            onAvatarChanged={onAvatarChanged}
            rectangle
            width="120px"
            height="184px"
            setUploading={setUploadingImage}
            style={{ borderRadius: "10px", marginBottom: "15px" }}
          />
        </div>
        <FormFooterActions>
          <Button
            label={"Save"}
            className="p-button-primary"
            type="submit"
            loading={loading}
          />
          <Button
            label={"Cancel"}
            className="p-button-danger ml-2"
            onClick={handleCloseModal}
          />
        </FormFooterActions>
      </form>
    </Modal>
  );
};

export default UploadReciptImageModal;
