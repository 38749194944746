import { Box, Divider, Typography } from "@mui/material";
import React from "react";

const Separator: React.FC = () => {
  return (
    <Box sx={{ position: "relative", marginY: 4 }}>
      {/* Light divider line with rounded edges */}
      <Divider
        sx={{
          borderColor: "#ddd",
          borderWidth: 1,
          borderRadius: "10px",
          opacity: 0.6,
        }}
      />
      {/* "History" label centered on top of the line */}
      <Typography
        variant="subtitle2"
        sx={{
          position: "absolute",
          top: "-10px",
          left: "50%",
          transform: "translateX(-50%)",
          backgroundColor: "#fff",
          paddingX: 2,
          color: "#555",
          fontWeight: "600",
          textTransform: "uppercase",
        }}>
        History
      </Typography>
    </Box>
  );
};

export default Separator;
