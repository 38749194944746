import axios from "axios";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "src/constants/access-token";
import { API_ROUTES } from "src/constants/api-routes";
import { HTTP_STATUS_CODES } from "src/constants/http-status-codes";
import { getAuthorization } from "src/utils/get-authorization";
import { getJWTExpireTime } from "src/utils/get-jwt-expire-time";
import { toast } from "src/utils/toast";

import { cacheService } from "./cache-service";
import { authService } from "./services/auth";

export const responseInterceptors = {
  successInterceptor(response: any) {
    if (response.config.customCache) {
      cacheService.setItem(response.config.cacheKey, response);
    }
    return response;
  },
  async errorInterceptor(error: any) {
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    }

    const statusCode = error?.response?.status;

    if (statusCode) {
      switch (statusCode) {
        case HTTP_STATUS_CODES.CACHED_RESPONSE:
          return Promise.resolve(error.response.data);
        case HTTP_STATUS_CODES.VALIDATION_ERROR:
        case HTTP_STATUS_CODES.SERVER_ERROR:
        case HTTP_STATUS_CODES.NETWORK_ERROR:
        case HTTP_STATUS_CODES.NOT_FOUND:
          toast.error(error.response?.data?.meta?.message);
          break;
        // case HTTP_STATUS_CODES.UN_AUTHORIZED:
        case HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY:
          toast.error(error.response?.data?.meta?.message);
          break;
        default:
          return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  },
};

const whtieList = [
  API_ROUTES.AUTH.LOGIN,
  API_ROUTES.AUTH.RESET_PASSWORD,
  API_ROUTES.AUTH.REFRESH_TOKEN,
];

export const requestInterceptors = {
  async beforeSent(config: any) {
    if (!config.byRefreshToken && !whtieList.includes(config?.url)) {
      await handleRefreshToken();
    }

    if (!config.byRefreshToken && !whtieList.includes(config?.url)) {
      config.headers = {
        ...config.headers,
        Authorization: getAuthorization(),
      };
    }
    return cacheService.handleCached(config);
  },
  onError(error: any) {
    return Promise.reject(error);
  },
};

const handleRefreshToken = async () => {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN);
  const token = localStorage.getItem(ACCESS_TOKEN);
  if (refreshToken && token) {
    const expTime = getJWTExpireTime(token);
    if (expTime < Date.now()) {
      try {
        const result = await authService.refresh(refreshToken);
        localStorage.setItem(ACCESS_TOKEN, result.data.payload);
      } catch (error) {
        localStorage.removeItem(ACCESS_TOKEN);
        window.location.reload();
        Promise.reject(error);
      }
    }
  }
};
