import { API_ROUTES } from "src/constants/api-routes";
import { AxiosInstance } from "../index";
import { IAxiosResponse } from "../types/axios-response";
import { IQudraEmail } from "../types/qudra-email";

export const qudraEmailService = {
  get() {
    return AxiosInstance.get<IAxiosResponse<IQudraEmail>>(
      API_ROUTES.QUDRA_EMAIL.ROOT,
    );
  },

  update(data?: IQudraEmail) {
    return AxiosInstance.post<IAxiosResponse<IQudraEmail>>(
      API_ROUTES.QUDRA_EMAIL.ROOT,
      data || {},
    );
  },
};
