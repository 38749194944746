import { Card } from "primereact/card";
import { Column, ColumnBodyOptions } from "primereact/column";
import { DataTable, DataTablePFSEvent } from "primereact/datatable";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { contractService } from "src/api/services/contract";
import { IGetListBody } from "src/api/types/base-data";
import { IContractListItem } from "src/api/types/contracts";
import ListingPage from "src/components/App/ListingPage";
import SortAndFilters from "src/components/App/SortAndFilters";
import {
  userDetailsOfContract,
  dateCreatedAtBodyTemplate,
  dateBodyTemlateOfContract,
} from "src/components/App/TableHelpers";
import { filterListNames } from "src/constants/filter-list-names";
import { DEFAULT_PAGE_SIZE } from "src/constants/pagination";
import { useListCSVDownload } from "src/hooks/use-list-csv-download";
import { useTitle } from "src/hooks/useTitle";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import { proFreelancerService } from "../../../../api/services/pro-freelancer";
import { IProFreelancerRequestListItem } from "../../../../api/types/pro-freelancer";
import dayjs from "dayjs";

const ProFreelancerManagement: React.FC = () => {
  const [contracts, setContracts] = useState<IProFreelancerRequestListItem[]>(
    [],
  );
  const [totalRecords, setTotalRecords] = useState(0);
  const { t } = useTranslation();
  useTitle("Pro Freelancer Management");
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<IGetListBody>();
  const [lazyParams, setLazyParams] = useState<DataTablePFSEvent>({
    filters: {},
    first: 0,
    multiSortMeta: [],
    page: 0,
    pageCount: 2,
    rows: DEFAULT_PAGE_SIZE,
    sortField: "",
    sortOrder: null,
  });

  const { exportLoading, exportResult } = useListCSVDownload(
    contractService.export,
    "contracts",
  );

  const fetchContracts = useCallback(async () => {
    try {
      setLoading(true);
      const body: IGetListBody = !!filters
        ? {
            ...filters,
            ...{
              skip: lazyParams.page! * DEFAULT_PAGE_SIZE,
              take: DEFAULT_PAGE_SIZE,
            },
          }
        : {
            skip: lazyParams.page! * DEFAULT_PAGE_SIZE,
            take: DEFAULT_PAGE_SIZE,
          };
      const result = await proFreelancerService.getAll(body);
      setTotalRecords(result.data.payload.pagination.totalItems);
      setContracts(result.data.payload.items);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [lazyParams, filters]);

  useEffect(() => {
    fetchContracts();
  }, [fetchContracts]);

  const onPageChange = (e: DataTablePFSEvent) => {
    setLazyParams(e);
  };

  const onSortAndFiltersChanged = (data: IGetListBody) => {
    setFilters(data);

    setLazyParams({
      ...lazyParams,
      page: 0,
      first: 1,
    });
  };

  const goToDetail = (rowData: IProFreelancerRequestListItem) => {
    return (
      <>
        <Link
          to={
            ROUTE_CONSTANTS.DASHBOARD.PRO_FREELANCERS.DETAILS.GET_BY_DATA(
              rowData.id,
            ).ABSOLUTE
          }>
          Details
        </Link>
      </>
    );
  };

  const categoryBodyTemplate = (rowData: IProFreelancerRequestListItem) => {
    return rowData.serviceCategories.map((cat) => cat.ml_title.en).join(", ");
  };

  const goToFreelancerDetailTemplate = (
    rowData: IProFreelancerRequestListItem,
  ) => {
    return (
      <Link
        to={
          ROUTE_CONSTANTS.DASHBOARD.FREELANCERS.DETAILS.GET_BY_DATA(
            rowData.user.id,
          ).ABSOLUTE
        }>
        {`${rowData.user.firstName} ${rowData.user.lastName}`}
      </Link>
    );
  };

  const freelancerJoinDateTemplate = (
    rowData: IProFreelancerRequestListItem,
  ) => {
    return <>{dayjs(rowData.user.createdAt).format("YYYY-MM-DD HH:mm")}</>;
  };

  const approveOrRejectedDateTemplate = (
    rowData: IProFreelancerRequestListItem,
  ) => {
    if (rowData.flows?.length > 0) {
      return (
        <>
          {dayjs(rowData.flows[0].approveOrRejectedDate).format(
            "YYYY-MM-DD HH:mm",
          )}
        </>
      );
    }
    return "---";
  };

  const getRateTemplate = (rowData: IProFreelancerRequestListItem) => {
    return rowData.user.rateAverage || "";
  };
  const getTotalPackageNumberTemplate = (
    rowData: IProFreelancerRequestListItem,
  ) => {
    return rowData.user.packageCount;
  };

  const getTotalServiceNumberTemplate = (
    rowData: IProFreelancerRequestListItem,
  ) => {
    return rowData.user.serviceCount;
  };

  return (
    <>
      <ListingPage title={"Pro Freelancer Management"}>
        {/* <SortAndFilters
          listName={filterListNames.CONTRACTS}
          onChange={onSortAndFiltersChanged}
          exportLoading={exportLoading}
          onExportClicked={exportResult}
        /> */}
        <Card>
          <DataTable
            value={contracts}
            emptyMessage="No Request found."
            loading={loading}
            // rowClassName={(data: IProFreelancerRequestListItem) => {
            //   return data.needAdminAction ? "highlighted-row" : "";
            // }}
            lazy
            first={lazyParams.first}
            paginator
            rows={DEFAULT_PAGE_SIZE}
            totalRecords={totalRecords}
            onPage={onPageChange}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            dataKey="id"
            className="qud-table">
            <Column field="id" header="ID" />
            <Column
              field="user"
              header="Freelancer"
              body={goToFreelancerDetailTemplate}
            />
            <Column
              field="user.createdAt"
              header="Joining Date"
              body={freelancerJoinDateTemplate}
            />
            <Column
              header="Approve/Rejected Date"
              body={approveOrRejectedDateTemplate}
            />
            <Column
              header="Total Package Number"
              body={getTotalPackageNumberTemplate}
            />
            <Column
              header="Total Service Number"
              body={getTotalServiceNumberTemplate}
            />
            <Column header="Rate" body={getRateTemplate} />
            <Column
              field="createdAt"
              header="Request Date"
              body={dateBodyTemlateOfContract}
            />
            <Column field="status" header="Status" />
            <Column
              field="Service Category"
              header="serviceCategories"
              body={categoryBodyTemplate}
            />
            {/* <Column field="type" header="Type" />
            <Column
              field="customer"
              header="Customer"
              body={userDetailsOfContract}
            />

            <Column field="recordId" header="Tracking Number" />

            */}
            {/* <Column
              field="dueDate"
              header="Due Date"
              body={dateBodyTemlateOfContract}
            />
            <Column field="dueDateStatus" header="Due Date Status" />
            <Column field="originPrice" header="Price" />
            <Column field="discountDetail" header="Discount Amount" />
            <Column field="price" header="Total" />
            <Column field="inAppStatus" header="Status" />
            <Column
              field="inAppStatus"
              header="Money Transfer"
              body={moneyTransferBodyTemplate}
            />
   */}
            <Column header="" body={goToDetail}></Column>
          </DataTable>
        </Card>
      </ListingPage>
    </>
  );
};

export default ProFreelancerManagement;
