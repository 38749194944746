import { IContract } from "src/api/types/contracts";
import { ContractType } from "../../../../api/types/contracts";
import { IProFreelancerRequestDetail } from "../../../../api/types/pro-freelancer";
export const getPersonalData = (
  proFreelancerRequestDetail: IProFreelancerRequestDetail,
) => {
  const data = [];
  data.push({
    label: "First Name",
    value: proFreelancerRequestDetail.proFreelancer.user.firstName || "",
  });

  data.push({
    label: "Last Name",
    value: proFreelancerRequestDetail.proFreelancer.user.lastName || "",
  });

  data.push({
    label: "Email",
    value: proFreelancerRequestDetail.proFreelancer.user.email,
  });

  data.push({
    label: "Categories",
    value:
      proFreelancerRequestDetail.proFreelancer.serviceCategories
        .map((cat) => cat.ml_title.en)
        .join(", ") || "",
  });

  data.push({
    label: "Linked In",
    value: proFreelancerRequestDetail.proFreelancer.linkedInProfile || "",
  });
  data.push({
    label: "Website",
    value: proFreelancerRequestDetail.proFreelancer.website || "",
  });
  data.push({
    label: "Portfolio",
    value: proFreelancerRequestDetail.proFreelancer.portfolioSite || "",
  });
  return data;
};

export const getFiles = (req: IProFreelancerRequestDetail) => {
  const data = [];
  if (req.cvFile) {
    data.push({
      name: req.cvFile.originalName,
      type: req.cvFile.type,
      url: req.cvFile.url,
    });
  }

  req.portfolioFiles.forEach((file) => {
    data.push({ name: file.originalName, type: file.type, url: file.url });
  });

  return data;
};

export const getImages = (req: IProFreelancerRequestDetail) => {
  const data = [];
  if (req.proFreelancer.passportMedia) {
    data.push({
      name: "Passport",
      url: req.proFreelancer.passportMedia.url,
    });
  }

  if (req.proFreelancer.idCardBackMedia) {
    data.push({
      name: "Id Card Back",
      url: req.proFreelancer.idCardBackMedia.url,
    });
  }

  if (req.proFreelancer.idCardFrontMedia) {
    data.push({
      name: "Id Card Front",
      url: req.proFreelancer.idCardFrontMedia.url,
    });
  }

  return data;
};

export const downLoadFile = (url: string, name: string) => {
  const serverLink = `https://dev.qudra.cloud/api/download?fileUrl=${url}&fileName=${name}`;
  return serverLink;
};
