import { API_ROUTES } from "src/constants/api-routes";
import { AxiosInstance } from "../index";
import {
  IAxiosResponse,
  IPaginatedRequestBody,
  PaginatedResponse,
} from "../types/axios-response";
import { ICategory, INewCategoryData } from "../types/category";
import { IPartner, INewPartnerData } from "../types/partner";
import {IMinimumPrice} from '../types/minimum-price';

export const minimumPriceService = {
  get() {
    return AxiosInstance.get<IAxiosResponse<IMinimumPrice>>(
      API_ROUTES.MINIMUM_PRICE.ROOT,

    );
  },

  update(data?: IMinimumPrice) {
    return AxiosInstance.post<IAxiosResponse<IMinimumPrice>>(
      API_ROUTES.MINIMUM_PRICE.ROOT,
      data || {},
    );
  },

};
