import { useState, useEffect, useCallback } from "react";
import Header from "src/components/App/PageHeader";

import { qudraEmailService } from "../../../../api/services/qudra-email";
import { IQudraEmail } from "../../../../api/types/qudra-email";
import QudraEmailForm from "./QudraEmailForm";
const GlobalSetting: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [currentConfig, setCurrentConfig] = useState<IQudraEmail>();

  const fetchCurrentConfig = useCallback(async () => {
    try {
      setLoading(true);
      const result = await qudraEmailService.get();
      console.log("result", result);
      setCurrentConfig(result.data.payload);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCurrentConfig();
  }, [fetchCurrentConfig]);

  return (
    <>
      <Header hasGoBack={true} title="Qudra Email" />
      {currentConfig ? <QudraEmailForm currentConfig={currentConfig} /> : <></>}
    </>
  );
};

export default GlobalSetting;
