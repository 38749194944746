import React, { useState } from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Modal,
  Box,
} from "@mui/material";

interface ImageCardProps {
  imageUrl: string;
  title?: string; // Optional title prop
}

const ImageCard: React.FC<ImageCardProps> = ({ imageUrl, title }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      {/* Image Card */}
      <Card
        sx={{
          maxWidth: 400,
          boxShadow: 4,
          borderRadius: 3,
          overflow: "hidden",
          transition: "transform 0.3s, box-shadow 0.3s",
          "&:hover": {
            transform: "scale(1.02)",
            boxShadow: 6,
          },
          cursor: "pointer",
        }}
        onClick={handleOpen}>
        <CardMedia
          component="img"
          height="250"
          width="250"
          image={imageUrl}
          alt={title || "Uploaded receipt"}
          sx={{
            objectFit: "cover",
            transition: "filter 0.3s",
            "&:hover": {
              filter: "brightness(1.1)",
            },
          }}
        />
        {title && (
          <CardContent>
            <Typography
              variant="subtitle2"
              sx={{
                textAlign: "center",
                fontWeight: "bold",
                color: "text.primary",
                mt: 1,
              }}>
              {title}
            </Typography>
          </CardContent>
        )}
      </Card>

      {/* Fullscreen Modal */}
      <Modal
        open={open}
        onClose={handleClose}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Box
          sx={{
            width: "90%",
            height: "90%",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 2,
            overflow: "hidden",
          }}>
          <img
            src={imageUrl}
            alt={title || "Full view of receipt"}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
              display: "block",
            }}
          />
        </Box>
      </Modal>
    </>
  );
};

export default ImageCard;
