import React, { useState } from "react";
import { TextField, Typography, Box, Stack } from "@mui/material";
import { IMinimumPrice } from "../../../../../api/types/minimum-price";
import { minimumPriceService } from "../../../../../api/services/minimumPrice";
import { toast } from "src/utils/toast";
import { Button } from "primereact/button";

type Props = {
  minimumPrice: IMinimumPrice;
};

const MinPriceForm = (props: Props) => {
  const { minimumPrice } = props;
  const [minJobPrice, setMinJobPrice] = useState<string>(
    minimumPrice.jobMinimumPrice.toString(),
  );
  const [minPackagePrice, setMinPackagePrice] = useState<string>(
    minimumPrice.packageMinimumPrice.toString(),
  );
  const [error, setError] = useState<{ job: string; package: string }>({
    job: "",
    package: "",
  });
  const [loading, setLoading] = useState<boolean>(false);

  const handleChange = (value: string, type: "job" | "package") => {
    if (/^\d*\.?\d*$/.test(value)) {
      type === "job" ? setMinJobPrice(value) : setMinPackagePrice(value);
      setError({ ...error, [type]: "" });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    let hasError = false;

    if (!minJobPrice || parseFloat(minJobPrice) <= 0) {
      setError((prev) => ({
        ...prev,
        job: "Minimum job price must be a positive number",
      }));
      hasError = true;
    }
    if (!minPackagePrice || parseFloat(minPackagePrice) <= 0) {
      setError((prev) => ({
        ...prev,
        package: "Minimum package price must be a positive number",
      }));
      hasError = true;
    }

    if (hasError) return;

    setLoading(true);
    await minimumPriceService.update({
      jobMinimumPrice: minJobPrice,

      packageMinimumPrice: minPackagePrice,
    });
    setLoading(false);
    toast.success("Config saved successfully!");
  };

  return (
    <Box
      sx={{
        maxWidth: 500,
        mx: "auto",
        p: 3,
        border: "1px solid #ddd",
        borderRadius: 2,
        boxShadow: 3,
      }}>
      <Typography variant="h5" align="center" gutterBottom>
        Set Minimum Prices
      </Typography>
      <form onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <TextField
            label="Minimum Job Price"
            variant="outlined"
            fullWidth
            value={minJobPrice}
            onChange={(e) => handleChange(e.target.value, "job")}
            error={!!error.job}
            helperText={error.job || "Enter a minimum job price in KD"}
          />
          <TextField
            label="Minimum Package Price"
            variant="outlined"
            fullWidth
            value={minPackagePrice}
            onChange={(e) => handleChange(e.target.value, "package")}
            error={!!error.package}
            helperText={error.package || "Enter a minimum package price in KD"}
          />
          {/* <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={loading}
            sx={{ mt: 3, py: 1.5, fontWeight: "bold" }}>
            Save Minimum Prices
          </Button> */}
          <Button
            label={"Save Minimum Prices"}
            className="p-button-primary w-full"
            type="submit"
            loading={loading}
          />
        </Stack>
      </form>
    </Box>
  );
};

export default MinPriceForm;
