import {
  IFreelancerServicePackage,
  IFreelancerServices,
  IFreelancerFinancialDetails,
} from "./base-data";
import { IBaseUser } from "./user";
import { IJobItem } from "./job";

export enum ContractType {
  JOB = "JOB",
  PACKAGE = "PACKAGE",
}

export interface IContractListItem {
  id: number;
  closingIsCalledByCustomer: boolean;
  customer: IBaseUser;
  dueDate: string;
  dueDateStatus: string;
  fee: string;
  price: string;
  originPrice: string;
  freelancer: IBaseUser;
  openDate: string;
  package: IFreelancerServicePackage;
  service: IFreelancerServices;
  serviceId: number;
  status: string;
  updatedAt: string;
  createdAt: string;
  needAdminAction: boolean;
  inAppStatus: string;
}

export interface IAvailableAction {
  name: string;
  commentTitle: string;
  needsFeedback: boolean;
}

export interface IAvailableActionResponse {
  comment?: string;
  actions: IAvailableAction[];
}

export interface IAvailableActionDropDown {
  key: string;
  title: string;
}

export interface IContactStatusModalState {
  show: boolean;
  data: IAvailableActionDropDown | undefined;
}

export interface IChangeStateBody {
  action: string;
  comment?: string;
}

export interface IUploadReceiptImageBody {
  mediaId: number;
}
export interface IContract {
  id: number;
  categoryId: number;
  closingIsCalledByCustomer: boolean;
  customer: IBaseUser;
  freelancer: IBaseUser;
  dueDate: string;
  dueDateStatus: string;
  fee: string;
  price: string;
  originPrice: string;
  couponAmount?: string;
  openDate: string;
  package?: IFreelancerServicePackage;
  packageTitle: string;
  service: IFreelancerServices;
  discountDetail?: string;
  financialDetail: IFreelancerFinancialDetails;
  serviceId: number;
  serviceTitle: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  job?: IJobItem;
  type: ContractType;
  0?: {
    url: string;
  };
}

export interface IChannelInfo {
  url: string;
  token: string;
  userId: string;
}

export interface IContractStatusHistory {
  comment?: string;
  createdAt: string;
  id: number;
  status: string;
}
